@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.save_btn {
  padding: 3px 30px;
  background-color: #4c4cf1;
  color: white;
  font-size: 18px;
  border: none;
  outline: none;
  border-radius: 8px;
}
