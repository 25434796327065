@tailwind base;
@tailwind components;
@tailwind utilities;

/* Hide scrollbar for modern browsers */
.scrollbar-hidden::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.scrollbar-hidden {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

/* Firefox (uncomment to work in Firefox, although other properties will not work!)  */
/** {
  scrollbar-width: thin;
  scrollbar-color: #495355 #6F6C6A;
}*/

/* Chrome, Edge and Safari */
.custom-scrollbar::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color: #262626;
  visibility: hidden;
  opacity: 0;
  transition: all ease 0.3s;
}

/* .custom-scrollbar::-webkit-scrollbar-track:hover {
  background-color: #262626;
}

.custom-scrollbar::-webkit-scrollbar-track:active {
  background-color: #424546;
} */

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #404040;
  visibility: hidden;
  opacity: 0;
  transition: all ease 0.3s;
}

/* .custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #313131;
}

.custom-scrollbar::-webkit-scrollbar-thumb:active {
  background-color: #313131;
} */

.custom-scrollbar:hover::-webkit-scrollbar-track {
  visibility: visible;
  opacity: 1;
}

.custom-scrollbar:hover::-webkit-scrollbar-thumb {
  visibility: visible;
  opacity: 1;
}


